import React, { Component } from "react";
import {
    MDBCollapse,
    MDBNavbar, MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarToggler,
    MDBNavItem,
    MDBNavLink,
} from "mdbreact";

class AuthenticatedLayout extends Component {

    constructor() {
        super();
        this.state = {
            isOpen: false
        }
    }



    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        return (
            <div className={'h-100 position-relative'} id="main-container">
                <MDBNavbar fixed={'top'} light expand={'md'} className={`py-0 white`} style={{minHeight: "56px"}}>
                    <div className={'container-xl'}>
                        <MDBNavbarToggler onClick={this.toggleCollapse} style={{minHeight: "56px"}}/>
                        <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
                            <MDBNavbarNav left className={'h-100 flex-md-row'}>
                                <MDBNavbarBrand className={'mr-5'}>NEW'EE® Connect</MDBNavbarBrand>
                                <MDBNavItem>
                                    <MDBNavLink to={'/home'} active={ window.location.pathname === "/home2"}>Accueil</MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem className={'position-relative'}>
                                    <MDBNavLink className={'nav-link Ripple-parent'} to={'#!'} onClick={() => {alert('Pour ajouter des contenus dans votre bibliothèque, veuillez contacter l\'équipe NEW\'EE.');}}>Bibliothèque</MDBNavLink>
                                </MDBNavItem>
                            </MDBNavbarNav>
                            <MDBNavbarNav right>
                                <MDBNavItem>
                                    <MDBNavLink to={'/logout'}>Se déconnecter</MDBNavLink>
                                </MDBNavItem>
                            </MDBNavbarNav>
                        </MDBCollapse>
                    </div>
                </MDBNavbar>
                <div style={{height:'100%', paddingTop:'56px', overflow: "auto"}} onClick={() => { this.setState({ isOpen: false })}}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default AuthenticatedLayout;

