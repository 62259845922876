import React, {Component} from "react";
import {MDBBtn} from "mdbreact";
import pad from "../../../utils/number_pad";

export default class CloudSongBox extends Component{
    render(){
        let modDate = new Date(this.props.modif);
        let formatModDate = [
                pad(modDate.getDate(),2),
                pad(modDate.getMonth()+1,2),
                pad(modDate.getFullYear(),4)
            ].join('/')
            +' '+
            [
                pad(modDate.getHours(),2),
                pad(modDate.getMinutes(),2),
                pad(modDate.getSeconds(),2)
            ].join(':');
        return (
            <div className={'d-flex flex-row justify-content-between align-items-center rgba-white-slight flex-nowrap p-3 my-2'}>
                <div className={""}>{this.props.keyNum+1}.</div>
                <div style={{lineHeight: '1.2em'}} className={'flex-grow-1 font-weight-bold ml-3 text-uppercase overflow-hidden text-nowrap text-truncate text-left'}>
                    {decodeURI(this.props.filename)}
                    <br/>
                    <small className={"font-weight-light"}>
                        Auteur - (modifié le: {formatModDate})
                    </small>
                </div>
                <div className={'d-flex'}>
                    <MDBBtn circle className={'shadow-none md-btn py-1 px-3'} disabled={this.props.active === false} color={"black"} size={'sm'} onClick={() => this.props.onClickAdd()}>ajouter</MDBBtn>
                </div>
            </div>
        );
    }
}
