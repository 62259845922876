import React, {Component} from "react";
import {MDBIcon, MDBTooltip} from "mdbreact";
import TasksDiv from "../tasks-div/tasks-div";
import icon from "../../../assets/img/1300-1300-max.png";
import "./box-status.css";

export default class BoxStatus extends Component{

    render(){
        let s=this.props.totalDuration%60
        s= s?s:0;
        let min=Math.floor(this.props.totalDuration/60)%60
        let h=Math.floor(this.props.totalDuration/3600)%3600
        return (
            <div className={'card w-100 h-100 p-3'}>
                <div  className="text-center d-flex flex-column h-100">
                    <div className={'flex-grow-1 d-flex justify-content-around flex-md-column justify-content-md-center align-items-center w-100'}>
                        <div>
                            <div style={{lineHeight: '1.1em'}} className={'mt-4 font-weight-bold text-uppercase mb-5'}>
                                {this.props.box_selected.name} <br/>
                                <small>({this.props.box_selected.mac_address})</small> <br/>
                                <small className={'font-weight-normal'}>
                                    DUREE TOTALE PLAYLIST:
                                    <span className={'text-lowercase ml-1'}>{this.props.totalDuration?(`${h?`${h}h `:''}${min?`${min}min `:''}${s}s`):'0s'}</span>
                                    <br/>
                                </small>
                            </div>
                            <div style={{lineHeight: '1.1em'}} className={'my-4 d-none d-md-block font-weight-bold text-uppercase'}>
                                {this.props.playingMusic? (
                                    <span>
                                    { decodeURI(this.props.playingMusic) }
                                        <br/>
                                    <small>auteur</small>
                                </span>
                                ): "Aucune musique jouée actuellement"}
                            </div>
                            <img src={icon} alt="icon" className={'newee-blue mx-2 song-img'}/>
                        </div>
                        <div className={''}>
                            <div style={{lineHeight: '1.1em'}} className={'my-2 d-md-none font-weight-bold text-uppercase'}>
                                {this.props.playingMusic? (
                                    <span>
                                    { this.props.playingMusic }
                                        <br/>
                                    <small>auteur</small>
                                </span>
                                ): "Aucune musique jouée actuellement"}
                            </div>
                            <div className={'d-flex mb-2 mt-3 my-md-4 my-xl-5 mx-md-4 justify-content-center align-items-center'}>
                                <MDBTooltip domElement tag="a" placement="top">
                                    <a href={'#!'} className={'text-dark newee-color-hover'} onClick={()=>this.props.onAddTask('prev',null,null)}><MDBIcon className={'mx-2 mx-md-3'} icon="backward" /></a>
                                    <span className={'text-light'}>Précédent</span>
                                </MDBTooltip>

                                {!this.props.playingMusic? (
                                    <MDBTooltip domElement tag="a" placement="top">
                                        <a href={'#!'} className={'text-dark newee-color-hover'} onClick={()=>this.props.onAddTask('play',this.props.first_song_id,null)}><MDBIcon className={'mx-2 mx-md-3'} style={{fontSize: '1.4em'}} icon="play" /></a>
                                        <span className={'text-light'}>Jouer</span>
                                    </MDBTooltip>
                                ) : (
                                    <MDBTooltip domElement tag="a" placement="top">
                                        <a href={'#!'} className={'text-dark newee-color-hover'} onClick={()=>this.props.onAddTask('pause',null,null)}><MDBIcon className={'mx-2 mx-md-3'} style={{fontSize: '1.4em'}} icon="pause" /></a>
                                        <span className={'text-light'}>Pause</span>
                                    </MDBTooltip>
                                )}
                                <MDBTooltip domElement tag="a" placement="top">
                                    <a href={'#!'} className={'text-dark newee-color-hover'} onClick={()=>this.props.onAddTask('next',null,null)}><MDBIcon className={'mx-2 mx-md-3'} icon="forward" /></a>
                                    <span className={'text-light'}>Suivant</span>
                                </MDBTooltip>
                            </div>
                            <div className={'d-flex mb-2 mb-md-4 mb-xl-5 justify-content-center align-items-center'}>
                                <MDBIcon icon={"volume-off"} style={{width: "1em"}}/>
                                <input defaultValue={`${this.props.volume}`} type="range" id="volume" name="volume" min="0" max="100" step="1" onMouseUp={(e)=>this.props.onAddTask('volume',null,`${e.target.value}`)} style={{width:'100%'}} className={'custom-range mx-2'}/>
                                <MDBIcon icon={"volume-up"} style={{width: "1em"}}/>
                            </div>
                            <div className={'d-flex mb-2 mb-md-4 mb-xl-5 mx-md-4 justify-content-center align-items-center'}>
                                <MDBTooltip domElement tag="a" placement="top">
                                    <a href={'#!'} className={this.props.box_selected && this.props.box_selected.auto_next_mode === 1 ? 'newee-color' : 'text-dark newee-color-hover'} onClick={()=>{if(!(this.props.box_selected && this.props.box_selected.auto_next_mode === 1))this.props.onAddTask('mode',null,'auto-next')}}><MDBIcon className={'mx-2 mx-md-3'} style={{fontSize:'1.5em'}} icon="angle-double-right" /></a>
                                    <span className={'text-light'}>Consécutif</span>
                                </MDBTooltip>
                                <MDBTooltip domElement tag="a" placement="top">
                                <a href={'#!'} className={this.props.box_selected && this.props.box_selected.loop_mode === 1 ? 'newee-color' : 'text-dark newee-color-hover'} onClick={()=>{if(!(this.props.box_selected && this.props.box_selected.loop_mode === 1))this.props.onAddTask('mode',null,'loop')}}><MDBIcon className={'mx-2 mx-md-3'} style={{fontSize:'1.1em'}} icon="undo-alt" /></a>
                                    <span className={'text-light'}>En boucle</span>
                                </MDBTooltip>
                                <MDBTooltip domElement tag="a" placement="top">
                                    <a href={'#!'} className={this.props.box_selected && this.props.box_selected.random_mode === 1 ? 'newee-color' : 'text-dark newee-color-hover'} onClick={()=>{if(!(this.props.box_selected && this.props.box_selected.random_mode === 1))this.props.onAddTask('mode',null,'random')}}><MDBIcon className={'mx-2 mx-md-3'} style={{fontSize:'1.2em'}} icon="random" /></a>
                                    <span className={'text-light'}>Aléatoire</span>
                                </MDBTooltip>
                            </div>
                        </div>
                    </div>
                    <TasksDiv tasks={this.props.tasks} onDeleteTask={id => { this.props.onDeleteTask(id); }} />
                </div>
            </div>
        );
    }
}
