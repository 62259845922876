import React, {Component} from "react";
import {MDBBtn, MDBIcon} from "mdbreact";
import './playlist-song-box.css';

export default class PlaylistSongBox extends Component{
    render(){
        let s=this.props.duration%60
        s= s?s:0;
        let min=Math.floor(this.props.duration/60)%60
        let h=Math.floor(this.props.duration/3600)%3600
        return (
            <div className={`playlist-song-box card d-flex flex-row justify-content-between align-items-center flex-nowrap p-3 my-2 ${this.props.playing? "newee-blue" : "white"}`}>
                <div className={"ml-3 h-100"}>
                    {this.props.keyNum+1}.
                    <MDBIcon className={'arrows position-absolute top left ml-2 mt-3 px-1 grey lighten-2 rounded cursor-pointer'} icon={'angle-up'} onClick={()=>{this.props.moveUp()}}/>
                    <MDBIcon className={'arrows position-absolute bottom left ml-2 mb-3 px-1 grey lighten-2 rounded cursor-pointer'} icon={'angle-down'} onClick={()=>{this.props.moveDown()}}/>
                </div>
                <div style={{lineHeight: '1.2em'}} className={'flex-grow-1 font-weight-bold ml-3 text-uppercase overflow-hidden text-nowrap text-truncate text-left'}>
                    {decodeURI(this.props.filename)}
                    <br/>
                    <small className={"font-weight-light"}>
                        Auteur<span className={'text-lowercase'}>{this.props.duration?(` - ${h?`${h}h `:''}${min?`${min}min `:''}${s}s`):''}</span>
                    </small>
                </div>
                <div className={'d-flex'}>
                    {
                        !this.props.playing ?
                            <MDBBtn circle className={'shadow-none md-btn py-1 px-3'} color={"black"} size={'sm'} onClick={() => this.props.onClickPlay()}>jouer</MDBBtn>
                            :null
                    }
                    <MDBBtn circle className={'shadow-none md-btn py-1 px-3'} color={"black"} size={'sm'} onClick={() => this.props.onClickTrash()}>Suppr.</MDBBtn>
                </div>
            </div>
        );
    }
}
