import React, {Component} from "react";
import {MDBIcon} from "mdbreact";
import './tasks-div.css'

export default class TasksDiv extends Component{

    getTaskIcon(label){
        let icon_text;
        switch (label){
            case 'play':
                icon_text = <MDBIcon className={'mx-1'} icon="play" />;
                break;
            case 'pause':
                icon_text = <MDBIcon className={'mx-1'} icon="pause" />;
                break;
            case 'next':
                icon_text = <MDBIcon className={'mx-1'} icon="forward" />;
                break;
            case 'prev':
                icon_text = <MDBIcon className={'mx-1'} icon="backward" />;
                break;
            case 'mode':
                icon_text = <MDBIcon className={'mx-1'} far icon="dot-circle" />;
                break;
            case 'volume':
                icon_text = <MDBIcon className={'mx-1'} icon="volume-down" />;
                break;
            default:
                icon_text = <MDBIcon className={'mx-1'} far icon="question-circle" />;
                break;
        }
        return icon_text;
    }

    render(){
        let tasks = ["Récupération des taches"];
        if(this.props.tasks){
            if( this.props.tasks ){
                if( this.props.tasks.length > 0 ){
                    tasks = [];
                    this.props.tasks.forEach((v,k)=>{
                        tasks.push(
                            <div key={k+"-"+Math.round(Math.random() * 100000)} onClick={()=>{this.props.onDeleteTask(v.id)}}>
                                {this.getTaskIcon( v.label)}
                            </div>
                        )
                    })
                } else {
                    tasks = ["Aucune tache"];
                }
            }
        }
        return (
            <div className={'tasks-div w-100 d-flex flex-column align-items-center py-1 mt-4 text-white border-top'}>
                <div className={'mb-1 text-truncate overflow-hidden text-nowrap text-dark'}>
                    Actions à venir:
                </div>
                <div className={'text-dark d-flex align-items-center'}>
                    {tasks}
                </div>
            </div>
        );
    }
}
