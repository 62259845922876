import {ajax_get, ajax_put, ajax_delete} from "./fetcher";

export function getAll(callback, error){
    if(localStorage.getItem('session_token')){
        ajax_get(process.env.REACT_APP_API_URL + 'box/getAll',
            {},
            localStorage.getItem('session_token'),
            function(data){
                if(callback) callback(data);
            },function (data){
                if(error) error(data);
            }
        );
    }else{
        if(error) error("invalid");
    }
}

export function setName(mac_address, name, callback, error){
    if(localStorage.getItem('session_token')){
        ajax_put(process.env.REACT_APP_API_URL + 'box/setName',
            {mac_address},
            {name},
            localStorage.getItem('session_token'),
            function(data){
                if(callback) callback(data);
            },function (data){
                if(error) error(data);
            }
        );
    }else{
        if(error) error("invalid");
    }
}
export function del(mac_address, callback, error){
    if(localStorage.getItem('session_token')){
        ajax_delete(process.env.REACT_APP_API_URL + 'box/delete',
            {mac_address},
            localStorage.getItem('session_token'),
            function(data){
                if(callback) callback(data);
            },function (data){
                if(error) error(data);
            }
        );
    }else{
        if(error) error("invalid");
    }
}
