import React, { Component } from "react";
import { MDBCol, MDBRow } from "mdbreact";
import AuthenticatedLayout from "../../layouts/auth/authenticated";
import {getAll as getBoxes} from "../../../utils/box";
import Box from "../../../items/home/box-box/box-box";
import './home.css';
import ProgressRing from "../../../items/home/progress_ring/progress_ring";

class Home extends Component {
    state={
        boxes:null
    }

    componentDidMount() {
        this.setState({
            boxes:null
        });
        this.updateBoxes();
        setInterval(() => {
            this.updateBoxes();
        },30000);
    }

    updateBoxes = () => {
        getBoxes(
            data => {
                this.setState({boxes: data.boxes});
            },
            data=> { this.setState({boxes: []}) }
        );
    }





    render() {
        let boxes: any[] = ['Checking Boxes'];
        let length = 0;
        let online = 0;
        let playing = 0;
        if(this.state.boxes){
            if(this.state.boxes.length > 0){
                boxes = [];
                this.state.boxes.forEach((v,k)=>{
                    length ++;
                    if(v.online){
                        online++;
                        if(v.playing){playing++;}
                    }
                    boxes.push(<Box key={v.id+v.mac_address} box={v} onClick={()=>{ this.props.onChangeBox(v) }} deleteSelf={()=>{this.setState({ boxes: this.state.boxes.filter(b => b.mac_address !== v.mac_address)})}} />);
                })
            } else {
                boxes = ['No box found'];
            }
        }

        return (
            <AuthenticatedLayout>
                <div className={`h-100 container-xl d-flex flex-column align-items-center`}>
                    <MDBRow className={'mt-4 mb-3 w-100'}>
                        <MDBCol className={'card d-flex flex-row justify-content-around m-3'} style={{backgroundColor: 'rgba(0,0,0,0.5)'}}>
                            <ProgressRing width={'150px'} height={'150px'} percent={(length/length)*100} >
                                <div className={'text-light text-center'} style={{lineHeight:'1'}}>
                                    <span style={{fontSize:'3em'}}>{length}</span>
                                    <br/>diffuseurs
                                </div>
                            </ProgressRing>
                            <ProgressRing width={'150px'} height={'150px'} percent={(online/length)*100} >
                                <div className={'text-light text-center'} style={{lineHeight:'1'}}>
                                    <span style={{fontSize:'3em'}}>{online}</span>
                                    <br/>diffuseurs en ligne
                                </div>
                            </ProgressRing>
                            <ProgressRing width={'150px'} height={'150px'} percent={(playing/length)*100} >
                                <div className={'text-light text-center'} style={{lineHeight:'1'}}>
                                    <span style={{fontSize:'3em'}}>{playing}</span>
                                    <br/>diffuseurs actifs
                                </div>
                            </ProgressRing>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow center className="position-relative custom-scrollbar align-items-center" style={{overflowX: "hidden",overflowY: "auto"}}>
                        {boxes}
                    </MDBRow>
                </div>
            </AuthenticatedLayout>
        );
    }
}

export default Home;
