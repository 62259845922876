function ajax(method, url, data, token, successFn, errorFn) {

    let init = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: method,
    }
    if(data) init.body = JSON.stringify(data);
    if(token) init.headers.Authorization = 'Bearer ' + token;

    fetch(url, init)
    .then(res => {
        if(res.ok){ return res.json(); }
        else{
            throw new Error('request failed');
        }
    })
    .then(function(res){ if(successFn) successFn(res) })
    .catch(function(res){ if(errorFn) errorFn(res);});
}

export function ajax_get(url, qParams, token, successFn, errorFn){
    let qParamsStr = "?";
    for (const [key, value] of Object.entries(qParams)) {
        qParamsStr += key + "=" + value + "&"
    }
    ajax('GET', url + qParamsStr.slice(0,-1), null, token, successFn, errorFn);
}
export function ajax_post(url, qParams, data, token, successFn, errorFn){
    let qParamsStr = "?";
    for (const [key, value] of Object.entries(qParams)) {
        qParamsStr += key + "=" + value + "&"
    }
    ajax('POST', url + qParamsStr.slice(0,-1), data, token, successFn, errorFn);
}
export function ajax_put(url, qParams, data, token, successFn, errorFn){
    let qParamsStr = "?";
    for (const [key, value] of Object.entries(qParams)) {
        qParamsStr += key + "=" + value + "&"
    }
    ajax('PUT', url + qParamsStr.slice(0,-1), data, token, successFn, errorFn);
}
export function ajax_delete(url, qParams, token, successFn, errorFn){
    let qParamsStr = "?";
    for (const [key, value] of Object.entries(qParams)) {
        qParamsStr += key + "=" + value + "&"
    }
    ajax('DELETE', url + qParamsStr.slice(0,-1), null, token, successFn, errorFn);
}
