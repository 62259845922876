import {ajax_delete, ajax_get, ajax_post, ajax_put} from "./fetcher";

export function getAllFromNextCloud(callback, error){
    if(localStorage.getItem('session_token')){
        ajax_get(process.env.REACT_APP_API_URL + 'box/song/getAllFromCloud',
            {},
            localStorage.getItem('session_token'),
            function(data){
                if(callback) callback(data);
            },function (data){
                if(error) error(data);
            }
        );
    }else{
        if(error) error("invalid");
    }
}
export function getAll(mac_address, callback, error){
    if(localStorage.getItem('session_token')){
        ajax_get(process.env.REACT_APP_API_URL + 'box/song/getAll',
            {mac_address},
            localStorage.getItem('session_token'),
            function(data){
                if(callback) callback(data);
            },function (data){
                if(error) error(data);
            }
        );
    }else{
        if(error) error("invalid");
    }
}
export function add(mac_address, url, filename, return_songs, callback, error){
    if(localStorage.getItem('session_token')){
        ajax_post(process.env.REACT_APP_API_URL + 'box/song/add',
            {mac_address, return_songs},
            {url, filename},
            localStorage.getItem('session_token'),
            function(data){
                if(callback) callback(data);
            },function (data){
                if(error) error(data);
            }
        );
    }else{
        if(error) error("invalid");
    }
}
export function del(mac_address, id, return_songs, callback, error){
    if(localStorage.getItem('session_token')){
        ajax_delete(process.env.REACT_APP_API_URL + 'box/song/delete',
            {mac_address, id, return_songs},
            localStorage.getItem('session_token'),
            function(data){
                if(callback) callback(data);
            },function (data){
                if(error) error(data);
            }
        );
    }else{
        if(error) error("invalid");
    }
}
export function setOrder(mac_address, arr, return_songs, callback, error){
    if(localStorage.getItem('session_token')){
        ajax_put(process.env.REACT_APP_API_URL + 'box/song/setOrder',
            {mac_address, return_songs},
            {order: arr},
            localStorage.getItem('session_token'),
            function(data){
                if(callback) callback(data);
            },function (data){
                if(error) error(data);
            }
        );
    }else{
        if(error) error("invalid");
    }
}
