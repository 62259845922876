import React, {Component} from "react";
import './progress_ring.css';

export default class ProgressRing extends Component{
    render(){
        let dashoffset = 251.325228 * ( this.props.percent ? 100 - this.props.percent : 100 ) / 100;
        console.log(this.props.percent);
        return (
            <div className={'position-relative'}>
                <svg xmlns="http://www.w3.org/2000/svg" width={this.props.width?this.props.width:"100px"} height={this.props.height?this.props.height:"100px"} viewBox="0 0 110 110">
                    <circle
                        stroke="white"
                        strokeWidth="5"
                        fill="transparent"
                        r="40"
                        cx="55"
                        cy="55"
                    />
                    <circle
                        stroke="#05C3DE"
                        strokeDasharray="251.325228 251.325228"
                        style={{strokeDashoffset: ""+dashoffset, transformOrigin:'center', transform: "rotate(-90deg)", transition:'.3s'}}
                        strokeWidth="5.5"
                        fill="transparent"
                        r="40"
                        cx="55"
                        cy="55"
                    />
                </svg>
                <div className={'position-absolute'} style={{top:'50%', left:'50%', transform:'translate(-50%,-50%)'}}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
