import React, { Component } from "react";
import {MDBCol, MDBRow, MDBModal, MDBModalHeader, MDBModalBody} from "mdbreact";
import AuthenticatedLayout from "../../../layouts/auth/authenticated";
import './box.css';
import CloudSongBox from '../../../../items/home/cloud-song-box/cloud-song-box';
import PlaylistSongBox from "../../../../items/home/playlist-song-box/playlist-song-box";
import {getAll as getBoxes} from "../../../../utils/box";
import {getAll as getTasks, add as addTask, del as delTask} from "../../../../utils/task";
import {getAllFromNextCloud as getSongsFromCloud, getAll as getSongs, add as addSong, del as delSong, setOrder as setSongOrder} from "../../../../utils/songs";
import BoxStatus from "../../../../items/home/box-status/box-status";
import array_moveTo from "../../../../utils/array_moveTo";


class Box extends Component {
    state={
        box_mac_address:null,
        box_selected:null,
        side_collapsed: true,
        cloud_songs: null,
        tasks: null,
        playlist: null,
        interval:null,
        cloudModal:false,
        dateOrder:false
    }

    componentDidMount() {
        console.log();
        this.setState({
            box_mac_address: window.location.pathname.split('/')[2],
            box_selected:null,
            side_collapsed: true,
            cloud_songs: null,
            tasks: null,
            playlist: null,
            cloudModal:false
        });
        this.updateCloudSongs();
        this.updateSelectedBox();
        let interval = setInterval(() => {
            this.updateCloudSongs();
            this.updateSelectedBox();
        },30000);
        this.setState({interval: interval});
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        //console.log(this.state);
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    updateSelectedBox = () => {
        getBoxes(
            data => {
                this.setState({box_selected: data.boxes.find( b => b.mac_address === this.state.box_mac_address)} ,() => {
                    this.updatePlaylist(this.state.box_selected);
                    this.updateTasks(this.state.box_selected);
                });
            },
            data=> { this.setState({boxes: []}) }
        );
    }

    updateCloudSongs = ()=>{
        getSongsFromCloud(
            data => {
                    this.setState({ cloud_songs:
                         data.songs.sort((song1, song2)=>{
                             if(this.state.dateOrder){
                                 if (song1.modif < song2.modif) return -1;
                                 if (song1.modif > song2.modif) return 1;
                             }
                            if (song1.filename < song2.filename) return -1;
                            if (song1.filename > song2.filename) return 1;
                            return 0;
                        })
                    });
                },
            data => { this.setState({cloud_songs: []}) }
        );
    }

    updateTasks = (box) => {
        getTasks( box.mac_address, 10,
            data => { this.setState({tasks: data.tasks}) },
            data => { this.setState({tasks: []}) }
        );
    }

    addSongToPlaylist = (song) => {
        addSong(this.state.box_selected.mac_address, song.url, song.filename, true,
            data => { this.setState({playlist: data.songs})},
            data => { this.setState({playlist: []}) }
        );
    }

    updatePlaylist= (box)=>{
        getSongs( box.mac_address,
            data => { this.setState({playlist: data.songs})},
            data => { this.setState({playlist: []}) }
        );
    }

    deleteSong=(song)=>{
        delSong(this.state.box_selected.mac_address, song.id, true,
            data => { this.setState({playlist: data.songs}) },
            data => { this.setState({playlist: []}) }
        );
        this.updateTasks(this.state.box_selected);
    }
    addTask=(label,song_id,data)=>{
        addTask(this.state.box_selected.mac_address, label, song_id, data, true, 10,
            data => { this.setState({tasks: data.tasks}) },
            data => { this.setState({tasks: []}) }
        );
    }
    removeTask=(id)=>{
        delTask(this.state.box_selected.mac_address, id, true, 10,
            data => { this.setState({tasks: data.tasks}) },
            data => { this.setState({tasks: []}) }
        );
    }

    cloudModal=()=>{
        this.setState({cloudModal:!this.state.cloudModal});
    }

    saveOrder=(ar_from, ar_to)=>{
        let musics = this.state.playlist;
        array_moveTo(musics, ar_from, ar_to);
        this.setState({playlist:musics});
        let len = 0
        musics.forEach(() =>{len++});
        let return_arr = [];
        for (let i=1; i <= len; i++){
            return_arr.push({id:musics[i-1].id, place:i});
        }
        setSongOrder(this.state.box_selected.mac_address, return_arr, true,
            data => {
            }, data => {
                array_moveTo(musics, ar_to, ar_from);
                this.setState({playlist:musics});
            }
        )
    }
    setDateOrder=()=>{
        this.setState({
            dateOrder: !this.state.dateOrder,
            cloud_songs: this.state.cloud_songs.sort((song1, song2)=>{
                if(!this.state.dateOrder){
                    if (song1.modif < song2.modif) return -1;
                    if (song1.modif > song2.modif) return 1;
                }
                if (song1.filename < song2.filename) return -1;
                if (song1.filename > song2.filename) return 1;
                return 0;
            })
        });
    }

    render() {
        let cloudSongsModified = this.state.cloud_songs;
        if( cloudSongsModified ){
            if( cloudSongsModified.length > 0 ){
                cloudSongsModified.forEach((v,k)=>{
                    cloudSongsModified[k].active = true;
                })
            }
        } else {
            cloudSongsModified = [];
        }
        let playlist = [<div key={'checkingSongs'} className={'card my-4 py-3'}>Checking songs</div>];
        let showActions = false;
        let playingMusic = null;
        let totalDuration = 0;
        if( this.state.playlist ){
            if( this.state.playlist.length > 0 ){
                playlist = [];
                showActions = true;
                this.state.playlist.forEach((v,k)=>{
                    if(v.playing){playingMusic = v.filename}
                    playlist.push(<PlaylistSongBox key={"song"+v.url} keyNum={k} filename={v.filename} duration={v.duration} playing = {v.playing} onClickPlay={()=>{this.addTask('play',v.id,null)}} onClickTrash={()=>{this.deleteSong(v)}} moveUp={()=>{this.saveOrder(k,k-1)}} moveDown={()=>{this.saveOrder(k,k+1)}}> {v.filename} </PlaylistSongBox>)
                    let csid = cloudSongsModified.findIndex(x => (x.url === v.url && x.filename === v.filename));
                    if(cloudSongsModified[csid]) cloudSongsModified[csid].active = csid === -1;
                    totalDuration += v.duration ? v.duration : 0;
                })
            } else {
                playlist = [<div key={'noSong'} className={'card my-4 py-3'}>Aucun son.</div>];
            }
        }
        let cloudSongs = [<div key={'checkingSongsOnCloud'} className={'my-4 py-3'}>Checking songs on cloud</div>];
        if( cloudSongsModified ){
            if( cloudSongsModified.length > 0 ){
                cloudSongs = [];
                cloudSongsModified.forEach((v,k)=>{
                    cloudSongs.push(<CloudSongBox key={"song"+v.url} active={v.active} keyNum={k} filename={v.filename} modif={v.modif} onClickAdd={()=>{this.addSongToPlaylist(v)}}>  </CloudSongBox>)
                })
            } else {
                cloudSongs = [<div key={'noSongOnCloud'} className={'w-100 text-center'}>Aucun contenu dans la bibliothèque de votre diffuseur</div>];
            }
        }
        if(this.state.mac_address === "") playlist = ['Sélectionnez un RPI'];



        return (
            <AuthenticatedLayout selected_box={this.state.box_selected} boxes={this.state.boxes} onChangeBox={mac_address=>{this.changeBox(mac_address)}}>
                <div className={`h-100 container-xl`}>
                    <MDBRow center className="position-relative h-100 custom-scrollbar" style={{overflowX: "hidden",overflowY: "auto"}}>
                        {
                            showActions ?
                                (
                                    <MDBCol size="12" md={"5"} xl="4" className={"py-3 d-flex flex-column"}>
                                        <BoxStatus playingMusic={playingMusic} totalDuration={totalDuration} volume={this.state.box_selected.volume} tasks={this.state.tasks} box_selected={this.state.box_selected} onAddTask={this.addTask} onDeleteTask={this.removeTask} first_song_id={this.state.playlist[0].id}/>
                                    </MDBCol>
                                )
                                :null
                        }
                        <MDBCol size="12" md={"7"} xl={showActions ? "8" : "12"}  className="h-100 py-3 h-100 d-flex flex-column">
                            <div className="h-100 text-center overflow-auto custom-scrollbar">
                                {playlist}
                            </div>
                            {this.state.playlist?<button className={`btn btn-primary`} onClick={() => {this.cloudModal()}}>Cliquer pour ajouter</button>:null}
                        </MDBCol>
                    </MDBRow>
                </div>
                <MDBModal isOpen={this.state.cloudModal} size={'lg'} toggle={() => {this.cloudModal()}} backdrop={true}>
                    <MDBModalHeader toggle={() => {this.cloudModal()}}>
                        Bibliothèque
                        <br/>
                        <span style={{fontSize: '12px'}}>Pour ajouter des contenus dans votre bibliothèque, veuillez contacter l'équipe NEW'EE.</span>
                    </MDBModalHeader>
                    <div className={"border-bottom py-2 px-4"}>
                        <div className='custom-control custom-switch'>
                            <input type='checkbox' className='custom-control-input' id='dateOrderSwitch' checked={this.state.dateOrder} onClick={this.setDateOrder} readOnly/>
                            <label className='custom-control-label' htmlFor='dateOrderSwitch' style={{cursor:'pointer'}}>
                                trier par date
                            </label>
                        </div>
                    </div>
                    <MDBModalBody>
                        {cloudSongs}
                    </MDBModalBody>
                </MDBModal>
            </AuthenticatedLayout>
        );
    }
}

export default Box;
