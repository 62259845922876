import {ajax_delete, ajax_get, ajax_post} from "./fetcher";

export function getAll(mac_address, limit, callback, error){
    if(localStorage.getItem('session_token')){
        ajax_get(process.env.REACT_APP_API_URL + 'box/task/getUndone',
            {mac_address, limit},
            localStorage.getItem('session_token'),
            function(data){
                if(callback) callback(data);
            },function (data){
                if(error) error(data);
            }
        );
    }else{
        if(error) error("invalid");
    }
}

export function add(mac_address, label, song_id, data, return_tasks, limit, callback, error){
    let queryData = data ? (song_id ? {label,song_id,data} : {label,data}) : (song_id ? {label,song_id} : {label});
    if(localStorage.getItem('session_token')){
        ajax_post(process.env.REACT_APP_API_URL + 'box/task/add',
            {mac_address, return_tasks, limit},
            queryData,
            localStorage.getItem('session_token'),
            function(data){
                if(callback) callback(data);
            },function (data){
                if(error) error(data);
            }
        );
    }else{
        if(error) error("invalid");
    }
}

export function del(mac_address, id, return_tasks, limit, callback, error){
    if(localStorage.getItem('session_token')){
        ajax_delete(process.env.REACT_APP_API_URL + 'box/task/delete',
            {mac_address, id, return_tasks, limit},
            localStorage.getItem('session_token'),
            function(data){
                if(callback) callback(data);
            },function (data){
                if(error) error(data);
            }
        );
    }else{
        if(error) error("invalid");
    }
}
