import React, {Component} from "react";
import {MDBCard, MDBIcon} from "mdbreact";
import './box-box.css';
import {Redirect} from 'react-router-dom';
import {setName as setBoxName, del as deleteBox} from "../../../utils/box";

export default class Box extends Component{
    state = {
        goto:false,
        modifying:false,
        deleting: false,
    };

    changeBoxName(name){
        setBoxName(this.props.box.mac_address, name, (data) => {
            console.log(data);
        }, (data)=>{
            console.error(data);
        })
    }

    deleteBoxHandler(){
        deleteBox(this.props.box.mac_address, data => {
            this.props.deleteSelf();
        }, data => {});
    }

    activateModify(){
        this.setState({modifying: true});
    }

    deactivateModify(){
        this.setState({modifying: false});
    }

    render(){
        return (
            <MDBCard className={`box_rpi text-dark m-3 ${this.props.box.online?'white':'bg-light'}`} onClick={() => {if(!this.state.modifying){this.setState({goto:true});}}} style={{cursor: 'pointer', width: '250px'}}>
                { this.state.goto && !this.state.modifying ? <Redirect to={'/box/'+this.props.box.mac_address}/> : null }
                <div className={'d-flex flex-column align-items-center py-2 position-absolute w-100 h-100 px-3 py-4'}>
                    <MDBIcon icon={this.props.box.online ? (this.props.box.playing ? 'volume-up' : 'volume-down') : 'volume-mute'} size={"lg"} style={{fontSize:'4em'}} className={'d-flex justify-content-center align-items-center mt-4 mb-4'} />
                    <small className={'font-weight-bold text-dark mb-3'} style={{fontSize: '.6em'}}>{this.props.box.mac_address}</small>
                    <div className={'w-100 mt-3'} style={{lineHeight: '.6'}}>
                        <div className={'mb-2 grey-text'}>Nom</div>
                        <input disabled
                               className={`font-weight-bold w-100 ${this.state.modifying ? 'active' : ''} ${this.props.box.online?'white':'bg-light'}`}
                               style={{fontSize: '1em', paddingRight:'25px'}} defaultValue={this.props.box.name}
                               onBlur={(e) => {console.log('name changed to' + e.target.value); this.changeBoxName(e.target.value); this.deactivateModify(); e.target.disabled=true;}}
                               onKeyPress={(e) => {if(e.key === "Enter"){ this.deactivateModify(); e.target.disabled=true;}}}
                               onClick={(e) => {e.preventDefault(); e.stopPropagation(); return false;}}
                        />
                        <MDBIcon icon="edit" style={{fontSize:'1em', transform: 'translateX(calc(-100% - 2px))', marginTop: '4px'}} className={'position-absolute'} onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.activateModify(); e.target.previousSibling.disabled=false; return false;}}/>
                    </div>
                    <span className={'position-absolute top left ml-2 mt-1'}><i className={this.props.box.online ? (this.props.box.playing ? 'fas fa-music green-text' : 'fas fa-circle green-text') : 'fas fa-circle red-text'}/></span>
                    <span className={`position-absolute top right mr-2 mt-1 ${this.state.deleting?'d-block':'d-none'}`} style={{fontSize: '.7em'}}>
                        êtes vous sur? <span style={{textDecoration: 'underline', fontSize:'1.3em'}} className={'red-text'} onClick={e => {
                            this.deleteBoxHandler(); e.preventDefault(); e.stopPropagation();
                        }}>oui</span>
                    </span>
                    <span className={`position-absolute top right mr-2 mt-1 delete ${this.state.deleting?'d-none':''} ${this.props.box.online?'d-none':''}`} style={{fontSize: '.8em'}}
                        onClick={e => { this.setState({deleting: true});  e.preventDefault(); e.stopPropagation();}}
                    ><MDBIcon icon={'trash'}/></span>
                </div>
            </MDBCard>
        );
    }
}
