export default function pad(num, size) {
    if(size>10) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    }else{
        let s = "000000000" + num;
        return s.substr(s.length-size);
    }
}
